<template>
  <div class="modal" id="addUserModal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Create User</h3>
      </div>
      <div class="modal-body" id="modal-scrollable">
        <div class="text-center mt-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="modal-body-container">
            <div class="modal-body-container_title">Account</div>
            <div class="form-check form-switch p-0">
              <label class="form-check-label ms-0 me-2" :class="{active: formData.isActive === false}" for="fieldIsActive">Inactive</label>
              <input class="form-check-input m-0" type="checkbox" id="fieldIsActive" v-model="formData.isActive">
              <label class="form-check-label ms-2" :class="{active: formData.isActive === true}" for="fieldIsActive">Active</label>
            </div>
            <div class="row mt-4">
              <div class="col-7">
                <div class="form-group full-width">
                  <label for="fieldEmail">Email address</label>
                  <input type="email" class="form-control" id="fieldEmail" v-model="formData.email"
                         :class="{'input-filled': formData.email.length}">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('email', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
              <div class="col-5">
                <div class="form-group full-width">
                  <label for="fieldPass">Password</label>
                  <div class="position-relative input-icon">
                    <img class="cursor-pointer" src="@/assets/icons/icon-Show password.svg" @click="switchVisibility" alt="Show pass">
                    <input :type="formData.passwordFieldType" class="form-control" id="fieldPass"
                           :class="{'input-filled': formData.password.length}" v-model="formData.password">
                  </div>
                  <span class="error-msg" v-for="(error, index) in getError('password', errors)" :key="index">
                    {{ error }}
                  </span>
                </div>
              </div>
              <div class="col-7 mt-3">
                <div class="form-group full-width">
                  <label for="fieldProfileVisibility">Profile visibility</label>
                  <multiselect v-model="formData.visibility"
                               placeholder="Select Profile Visibility"
                               label="label"
                               track-by="value"
                               id="fieldProfileVisibility"
                               class="full-width"
                               :options="visibilityList"
                               :searchable="true"
                               :close-on-select="true"
                               :allow-empty="false"
                               :show-labels="false">
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="modal-body-container_title">User Details</div>
            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldFirstName">First name</label>
                  <input type="text" class="form-control" id="fieldFirstName" v-model="formData.firstName"
                         :class="{'input-filled': formData.firstName.length}">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('firstName', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldLastName">Last name</label>
                  <input type="text" class="form-control" id="fieldLastName" v-model="formData.lastName"
                         :class="{'input-filled': formData.lastName.length}">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('lastName', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldTitle">Title</label>
                  <multiselect v-model="formData.title"
                               label="name" track-by="id"
                               placeholder="Select Title"
                               id="fieldTitle"
                               class="full-width"
                               :options="titles"
                               :searchable="true"
                               :close-on-select="true"
                               :allow-empty="false"
                               :show-labels="false">
                  </multiselect>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldRole">Primary role</label>
                  <multiselect v-model="formData.role"
                               placeholder="Select Role"
                               id="fieldRole"
                               class="full-width"
                               :options="roles"
                               :searchable="true"
                               :close-on-select="true"
                               :allow-empty="false"
                               :show-labels="false">
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldNMLS">NMLS</label>
                  <input type="text" class="form-control" id="fieldNMLSL" v-model="formData.NMLS"
                         :class="{'input-filled': formData.NMLS.length}">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('nmls', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldState">States</label>
                  <div>
                    <multiselect v-model="formData.state" id="fieldState"
                                 label="name" track-by="id"
                                 :options="states" :multiple="true"
                                 :close-on-select="false"
                                 :clear-on-select="false"
                                 class="full-width fieldState"
                                 :preserve-search="true"
                                 :selectedLabel="'✓'"
                                 placeholder="Pick some">
                      <template slot="selection" slot-scope="{ values, search, isOpen }">
                        <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                          {{ values.length }} options selected
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldDob">Date of birth</label>
                  <input type="date" class="form-control" id="fieldDob" v-model="formData.dob"
                         :class="{'input-filled': formData.dob.length}">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('dob', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>

          </div>
          <div class="modal-body-container">
            <div class="row">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldBranch">Branch</label>
                  <multiselect v-model="formData.branch"
                               label="name" track-by="id"
                               placeholder="Select Branch"
                               id="fieldBranch"
                               class="full-width"
                               :options="branches"
                               :searchable="true"
                               :close-on-select="true"
                               :show-labels="false">
                  </multiselect>
                </div>

              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldHired">Hired by</label>
                  <multiselect v-model="formData.hired"
                               label="name" track-by="id"
                               id="fieldHired"
                               class="full-width"
                               :options="hiredList"
                               :searchable="true"
                               :close-on-select="true"
                               :allow-empty="false"
                               :show-labels="false">
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-if="ui.loading === false">
        <base-button title="Add User" action="secondary-default" @click-btn="addUser" :disabled="ui.saving" :loading="ui.saving" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import PerfectScrollbar from 'perfect-scrollbar';
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: 'AddNewUser',
  components: { Multiselect, BaseButton },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        title: '',
        NMLS: '',
        branch: '',
        email: '',
        phone: '',
        fax: '',
        role: '',
        state: [],
        password: '',
        hired: '',
        dob: '',
        visibility: '',
        passwordFieldType: 'password',
        isActive: true
      },
      roles: [],
      titles: [],
      states: [],
      branches: [],
      hiredList: [],
      visibilityList: [],
      ui: {
        saving: false,
        loading: false,
        ps: null
      },
      errors: []
    }
  },
  methods: {
    loadData() {
      this.ui.loading = true;
      this.$http.get('/api/v1/staff/register')
        .then((res) => {
          this.roles = res.data.roles
          this.titles = res.data.titles
          this.states = res.data.licenseList
          this.hiredList = res.data.hiredBy
          this.branches = res.data.branches
          this.visibilityList = res.data.visibilityList

          /** Set Default Values */
          this.formData.role = this.roles[0]
          this.formData.title = this.titles[0]
          this.formData.hired = this.hiredList[0]
          this.formData.branch = this.branches[0]
          this.formData.state.push(this.states[0])
          this.formData.visibility = this.visibilityList[0]
        })
        .finally(() => {
          this.ui.loading = false;
        })
    },
    addUser() {
      this.ui.saving = true
      this.errors = []

      if (this.formData.password.trim() === '') {
          this.errors.push({
              path: 'password',
              message: 'This value should not be blank.',
              label: 'password'
          })
          this.alertError('Validation error. Please check your data.')
          this.ui.saving = false
          return false;
      }

      let formData = new FormData()
      formData.append('status', this.formData.isActive)
      formData.append('email', this.formData.email)
      formData.append('password', this.formData.password)
      formData.append('visibility', this.formData.visibility.value)
      formData.append('firstName', this.formData.firstName)
      formData.append('lastName', this.formData.lastName)
      formData.append('title', this.formData.title ? this.formData.title.id : 0)
      formData.append('role', this.formData.role)
      formData.append('nmls', this.formData.NMLS)
      formData.append('dob', this.formData.dob)
      formData.append('branch', this.formData.branch ? this.formData.branch.id : 0)
      formData.append('hiredBy', this.formData.hired ? this.formData.hired.id : 0)

      this.formData.state.forEach((state) => {
        formData.append('states[]', state.id)
      });

      this.$http.post('/api/v1/staff/register', formData)
        .then((res) => {
          if (res.data.status === 'success') {
            this.alertSuccess(res.data.message)
            this.$emit('success')
            this.$emit('close')
          } else {
            this.alertError(res.data.message)
          }
        })
        .catch((err) => {
          if (err.response.data.errors || err.response.status === 400) {
            this.errors = err.response.data.errors
            this.alertError(err.response.data.message)
          } else if (err.response.status === 502) {
            this.alertError(err.response.data.message)
          }else {
            this.alertError(err.response.statusText)
          }
        })
        .finally(() => {
          this.ui.saving = false
        })
    },
    switchVisibility() {
      this.formData.passwordFieldType = this.formData.passwordFieldType === 'password' ? 'text' : 'password'
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    const scrollbar = document.getElementById('modal-scrollable');
    this.ui.ps = new PerfectScrollbar(scrollbar);
  }
}
</script>

<style lang="scss" scoped>
.modal-body-container::v-deep .multiselect {

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    padding: 8px 40px 0 8px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}

#modal-scrollable {
  position: relative;
  overflow: hidden;
  height: calc(100% - 100px);
}
#addUserModal {
  .form-switch {
    margin: 0;
    label {
      opacity: 0.46;
      color: rgba(0,0,0,1);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      &.active {
        color: rgba(0,0,0,1);
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        opacity: 1;
      }
    }
  }
  .fieldState {
    .multiselect__option {
      height: 30px;
      min-height: 30px;
      max-height: 30px;
      padding: 8px;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
    }
    .multiselect__option--highlight {
      &:after {
        display: block;
        background: none;
        height: 30px;
        line-height: 30px;
      }
    }
    .multiselect__option--selected {
      background-color:  rgba(0, 118, 255, .1);
      span {
        color: #000000;

      }
      &:after {
        color:  #0078FF;
      }
    }
  }

  .input-icon {
    img {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

</style>
