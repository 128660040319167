<template>
  <div class="modal" id="EditUserModal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Edit User
        </h3>
      </div>
      <div class="modal-body" id="modal-body-scroll">
        <div class="text-center mt-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="modal-body-container">
            <div class="modal-body-container_title">
              Account
            </div>
            <div class="form-check form-switch p-0">
              <label class="form-check-label ms-0 me-2" :class="{active: formData.isActive === false}" for="fieldIsActive">Inactive</label>
              <input class="form-check-input m-0" type="checkbox" id="fieldIsActive" v-model="formData.isActive">
              <label class="form-check-label ms-2" :class="{active: formData.isActive === true}" for="fieldIsActive">Active</label>
            </div>
            <div class="row mt-4">
              <div class="col-7">
                <div class="form-group full-width">
                  <label for="fieldEmail">
                    Email address
                  </label>
                  <input type="email" class="form-control" id="fieldEmail"
                         :class="{'input-filled': formData.email.length}"
                         v-model="formData.email">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('email', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="modal-body-container_title">
              User Details
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldFirstName">
                    First name
                  </label>
                  <input type="text" class="form-control" id="fieldFirstName"
                         :class="{'input-filled': formData.firstName.length}"
                         v-model="formData.firstName">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('firstName', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldLastName">
                    Last name
                  </label>
                  <input type="text" class="form-control" id="fieldLastName"
                         :class="{'input-filled': formData.lastName.length}"
                         v-model="formData.lastName">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('lastName', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="row mt-3" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldTitle">Title</label>
                  <multiselect v-model="formData.title"
                               label="name" track-by="id"
                               placeholder="Select Title"
                               id="fieldTitle"
                               class="full-width"
                               :options="titles"
                               :searchable="true"
                               :close-on-select="true"
                               :allow-empty="false"
                               :show-labels="false">
                  </multiselect>
                </div>
                <span class="error-msg" v-for="(error, index) in getError('title', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldNMLS">
                    NMLS
                  </label>
                  <input type="text" class="form-control" id="fieldNMLS"
                         :class="{'input-filled': formData.NMLS.length}"
                         v-model="formData.NMLS">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('nmls', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label>States</label>
                  <div>
                    <span v-for="(state, index) in formData.state" :key="index">
                      {{ state.name }},
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldLicenses">Licenses</label>
                  <div>
                    <multiselect v-model="formData.licenses" id="fieldLicenses"
                                 label="name" track-by="id"
                                 :options="licenseList" :multiple="true"
                                 :close-on-select="false"
                                 :clear-on-select="false"
                                 class="full-width"
                                 :preserve-search="true"
                                 placeholder="Select State License">
                      <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                        {{ values.length }} options selected
                      </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-body-container">
            <div class="row">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldPhone">
                    Phone
                  </label>
                  <input type="text" class="form-control" id="fieldPhone"
                         :class="{'input-filled': formData.phone.length}"
                         v-model="formData.phone"
                         v-mask="'(###) ###-####'">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('phone', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldFax">
                    Fax
                  </label>
                  <input type="text" class="form-control" id="fieldFax"
                         :class="{'input-filled': formData.fax.length}"
                         v-model="formData.fax">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('fax', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
          <div class="modal-body-container" v-if="isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_ANALYST')">
            <div class="row">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldVisionDate">
                    Added To Vision
                  </label>
                  <input type="date" class="form-control" id="fieldVisionDate" v-model="formData.addedVisionDate"
                         :class="{'input-filled': formData.addedVisionDate ? formData.addedVisionDate.length : null}">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('addedVisionDate', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldInavtiveDate">
                    Inactive Date
                  </label>
                  <input type="date" class="form-control" id="fieldInavtiveDate" v-model="formData.inactiveDate"
                         :class="{'input-filled': formData.inactiveDate ? formData.inactiveDate.length : null}">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('inactiveDate', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="row">
              <div class="col-6">
                <div class="form-group full-width">
                  <label for="fieldBranch">
                    Branch
                  </label>
                  <multiselect v-model="formData.branch"
                               label="name" track-by="id"
                               placeholder="Select Branch"
                               id="fieldBranch"
                               class="full-width"
                               :options="branches"
                               :searchable="true"
                               :close-on-select="true"
                               :allow-empty="false"
                               :show-labels="false">
                  </multiselect>
                </div>

              </div>
              <div class="col-6" v-if="isGranted('ROLE_CEO_ASSISTANT') && $store.getters.getUserId != $route.params.id">
                <div class="form-group full-width">
                  <label for="fieldHired">
                    Hired by
                  </label>
                  <multiselect v-model="formData.hired"
                               label="name" track-by="id"
                               placeholder="Select Hired By"
                               id="fieldHired"
                               class="full-width"
                               :options="hiredList"
                               :searchable="true"
                               :close-on-select="true"
                               :allow-empty="false"
                               :show-labels="false">
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="col-12">
              <div class="form-group full-width">
                <label for="fieldAbout">
                  About
                </label>
                <ckeditor :editor="editor"
                          v-model="formData.about"
                          id="fieldContent"
                          :config="editorConfig">
                </ckeditor>
              </div>
            </div>
          </div>
          <div class="modal-body-container border-0">
            <div class="modal-body-container_title">
              User Links
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group full-width">
                  <label for="fieldApplyUrl">
                    Apply url
                  </label>
                  <input type="text" class="form-control" id="fieldApplyUrl"
                         :class="{'input-filled': formData.applyUrl.length}"
                         v-model="formData.applyUrl">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('applyURL', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
              <div class="col-12 mt-3">
                <div class="form-group full-width">
                  <label for="fieldRate">
                    Rate quote url
                  </label>
                  <input type="text" class="form-control" id="fieldRate"
                         :class="{'input-filled': formData.rateQuoteUrl.length}"
                         v-model="formData.rateQuoteUrl">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('rateQuoteURL', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
              <div class="col-12 mt-3">
                <div class="form-group full-width">
                  <label for="fieldCalendyUrl">
                    Calendy url
                  </label>
                  <input type="text" class="form-control" id="fieldCalendyUrl"
                         :class="{'input-filled': formData.calendyUrl.length}"
                         v-model="formData.calendyUrl">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('calendyURL', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
              <div class="col-12 mt-3">
                <div class="form-group full-width">
                  <label for="fieldDocumentsUrl">
                    Documents url
                  </label>
                  <input type="text" class="form-control" id="fieldDocumentsUrl"
                         :class="{'input-filled': formData.documentsUrl.length}"
                         v-model="formData.documentsUrl">
                </div>
                <span class="error-msg" v-for="(error, index) in getError('documentsURL', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-if="ui.loading === false">
        <button type="submit"
                @click="saveUser"
                class="btn bg-primary submit">
          <span v-if="ui.saving"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
          <span v-if="!ui.saving">Save</span>
        </button>
        <button type="reset" class="btn btn-cancel ms-4" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import PerfectScrollbar from 'perfect-scrollbar';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'EditUserModal',
  components: { Multiselect },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        basicEntities: false,
        autoParagraph: false
      },
      formData: {
        firstName: '',
        lastName: '',
        title: '',
        NMLS: '',
        branch: '',
        email: '',
        phone: '',
        fax: '',
        role: '',
        state: [],
        licenses: [],
        about: '',
        applyUrl: '',
        rateQuoteUrl: '',
        calendyUrl: '',
        documentsUrl: '',
        hired: '',
        isActive: false
      },
      roles: [],
      titles: [],
      states: [],
      licenseList: [],
      branches: [],
      hiredList: [],
      ui: {
        saving: false,
        loading: false,
        ps: null
      },
      errors: []
    }
  },
  methods: {
    loadData() {
      this.ui.loading = true;
      this.$http.get(`/api/v1/staff/edit/${this.$route.params.id}`)
        .then((res) => {
          this.formData = res.data.agent
          this.roles = res.data.data.roles
          this.titles = res.data.data.titles
          this.states = res.data.data.states
          this.licenseList = res.data.data.licenseList
          this.hiredList = res.data.data.hiredBy
          this.branches = res.data.data.branches
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.loading = false;
        })
    },
    saveUser() {
      this.ui.saving = true;
      this.errors = []

      let formData = new FormData()
      formData.append('status', this.formData.isActive)
      formData.append('email', this.formData.email)
      formData.append('firstName', this.formData.firstName)
      formData.append('lastName', this.formData.lastName)
      formData.append('title', this.formData.title ? this.formData.title.id : 0)
      formData.append('nmls', this.formData.NMLS)
      formData.append('branch', this.formData.branch ? this.formData.branch.id : 0)
      formData.append('phone', this.formData.phone)
      formData.append('fax', this.formData.fax)
      formData.append('about', this.formData.about)
      formData.append('applyURL', this.formData.applyUrl)
      formData.append('rateQuoteURL', this.formData.rateQuoteUrl)
      formData.append('calendyURL', this.formData.calendyUrl)
      formData.append('documentsURL', this.formData.documentsUrl)
      formData.append('hiredBy', this.formData.hired ? this.formData.hired.id : 0)
      formData.append('inactiveDate', this.formData.inactiveDate)
      formData.append('visionDate', this.formData.addedVisionDate)

      this.formData.state.forEach((state) => {
        formData.append('states[]', state.id)
      });

      this.formData.licenses.forEach((license) => {
        formData.append('licenses[]', license.id)
      });

      this.$http.post(`/api/v1/staff/edit/${this.$route.params.id}`, formData)
        .then((res) => {
          if (res.data.status === 'success') {
            this.alertSuccess(res.data.message)
            this.$emit('success')
            this.$emit('close')
            this.$root.$emit('updateUser')
          } else {
            this.alertError(res.data.message)
          }
        })
        .catch((err) => {
            if (err.response.data.errors || err.response.status === 400) {
              this.errors = err.response.data.errors
              this.alertError(err.response.data.message)
            } else if (err.response.status === 502) {
              this.alertError(err.response.data.message)
            }else {
              this.alertError(err.response.statusText)
            }
        })
        .finally(() => {
          this.ui.saving = false
        })
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar);
  }
}
</script>

<style lang="scss" scoped>
.modal-body-container::v-deep .multiselect {

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    padding: 8px 40px 0 8px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}

#modal-body-scroll {
  position: relative;
  height: calc(100% - 100px);
}
#EditUserModal {
  .form-switch {
    margin: 0;
    label {
      opacity: 0.46;
      color: rgba(0,0,0,1);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      &.active {
        color: rgba(0,0,0,1);
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        opacity: 1;
      }
    }
  }
  .input-icon {
    img {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
.ck.ck-editor__main>.ck-editor__editable{
  min-height: 200px!important;
  border-bottom-left-radius: 8px!important;
  border-bottom-right-radius: 8px!important;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
